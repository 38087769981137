export default [
  {
    header: "Dashboards",
    icon: "HomeIcon",
    children: [
      {
        title: "Lending",
        route: "dashboard-lending",
        icon: "MessageSquareIcon",
        action: "read",
        resource: "LENDING",
      },
      {
        title: "eCommerce",
        route: "dashboard-ecommerce",
        icon: "ShoppingCartIcon",
      },
      {
        title: "Analytics",
        route: "dashboard-analytics",
        icon: "ActivityIcon",
      },
    ],
  },
];
