export default [
  {
    header: "Others",
    icon: "MoreHorizontalIcon",
    children: [
      {
        title: "Access Control",
        route: "access-control",
        icon: "ShieldIcon",
        // disabled: true,
        action: "read",
        resource: "ACL",
      },
      {
        title: "Lending Module",
        icon: "MenuIcon",
        // action: "read",
        // resource: "LENDING",
        children: [
          {
            title: "Cash Advance",
            route: "apps-vale-list",
            action: "read",
            // resource: "LENDING",
          },
          {
            title: "Reports",
            children: [
              {
                title: "Voucher",
                route: null,
                action: "read",
                resource: "LENDING",
              },
              {
                title: "For Deduction",
                route: null,
                action: "read",
                resource: "LENDING",
              },
            ],
          },
        ],
      },
      {
        title: "Disabled Menu",
        route: null,
        icon: "EyeOffIcon",
        disabled: true,
      },
      {
        title: "Raise Support",
        href: "https://pixinvent.ticksy.com/",
        icon: "LifeBuoyIcon",
      },
      {
        title: "Documentation",
        href: "https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation",
        icon: "FileTextIcon",
      },
    ],
  },
];
