export default [
  {
    title: "Dashboards",
    icon: "HomeIcon",
    tag: "", // '2'
    tagVariant: "light-warning",
    children: [
      {
        title: "Lending",
        route: "dashboard-lending",
        action: "read",
        resource: "LENDING",
      },
      {
        title: "eCommerce",
        route: "dashboard-ecommerce",
      },
      {
        title: "Analytics",
        route: "dashboard-analytics",
      },
    ],
  },
];
